@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');



*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
}

body{
  font-family: 'Montserrat';
}

.App {
  text-align:initial;
  flex:1;
  overflow-y:"none";
  /* use image background from local assets/fotos/fondo.png */;
  background-image: url('/src/assets/fotos/fondo.png');
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
  position: absolute;
  align-items:flex-start;
 
} */

.rm-audio-player-provider {
  --rm-audio-player-interface-container: #f0e6e4;
  --rm-audio-player-progress-bar-background: rgb(242, 170, 168);
  --rm-audio-player-progress-bar:rgb(126 100 73);
  }


  .interface-grid{
    margin-top: -10px;

  }

  .rm-audio-player-provider button {
    margin: 0px;
    padding: 0px;
    background: transparent;
    cursor: pointer;
    vertical-align: baseline;
    border: 0px;
    color: rgb(242, 170, 168);
  }

.App-logo {
  width: 100%;

  align-items:flex-start;
  height: 100%;
}

.App-logo-right {
  height: 60vmin;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.FooterLeft {
  height: 30vmin;
  pointer-events: none;
  align-self: flex-start;
  margin-top: -35vmin;
  margin-left: -30px;
}

.FooterRight{
  height: 30vmin;
  pointer-events: none;
  align-self: flex-end;
  margin-top: -30vmin;
}

.TimerDiv {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  margin: "0 10px";
}

.Subtitle {
  height: 60vmin;
  pointer-events: none;
  margin-top: -1rem;;
}

.Footer {
  height: 100vmin;
  pointer-events: none;
  margin-top:-45vmin;
}

.Nombres {
  height: 85vmin;
  pointer-events: none;
  margin-top: 15vmin;;
  width: 100%;
}

.Estamos {
  height: 60vmin;
  pointer-events: none;
  margin-top: -45vmin;
  justify-self:center;
}

.Countdown {
  height: 15vmin;
  pointer-events: none;
  align-self: center;
}

.title{
  font-size: 1.5rem;
  font-weight: 300;
  color: #7A654D;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 10rem;
  text-transform: uppercase;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 */
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .gallery {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.gallery img {
  width: 100%;
  height: auto;
}
 */

 .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 70%, rgba(242, 170, 168, 1) 100%);
}

.container{
  background-color: white;
  display: grid;
  grid-template-columns: repeat(6,1fr);
  grid-auto-rows: 100px 300px;
  grid-gap: 10px;
  grid-auto-flow: dense;
}

.gallery-item{
  background-color: white;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-item .image{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery-item .image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  cursor: pointer;
  transition:.5s ease-in-out;
}


.gallery-item .text{
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: white;
  font-size: 25px;
  pointer-events: none;
  z-index: 4;
  backdrop-filter: blur(5px) saturate(1.8);
}


.w-1{
  grid-column: span 1;
}

.w-2{
  grid-column: span 2;
}

.w-3{
  grid-column: span 3;
}

.w-4{
  grid-column: span 4;
}

.w-5{
  grid-column: span 5;
}

.w-6{
  grid-column: span 6;
}

.h-1{
  grid-row: span 1;
}

.h-2{
  grid-row: span 2;
}

.h-3{
  grid-row: span 3;
}

.h-4{
  grid-row: span 4;
}

.h-5{
  grid-row: span 5;
}

.h-6{
  grid-row: span 6;
}





@keyframes move-down{

  0%{
    top: 10%;
  }
  50%{
    top: 35%;
  }
  100%{
    top: 50%;
  }
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
